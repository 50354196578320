var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.detailNews)?_c('b-container',{staticClass:"detail-page px-0 pb-0",attrs:{"fluid":""}},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.detailNews.article_thumbnail,"alt":""}})]),_c('b-container',{staticClass:"detail-news-content"},[_c('div',{staticClass:"info-news-detail"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime( _vm.detailNews.created_at, _vm.detailNews.article_lang ))+" ")]),_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(_vm.detailNews.article_blog_name)+" ")])]),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.detailNews.article_title))]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.detailNews.article_description)}}),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.detailNews.article_content)}})])]),_c('b-container',{staticClass:"related-news",attrs:{"fluid":""}},[_c('b-container',{staticClass:"related-news-post"},[_c('div',{staticClass:"section-news"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('detail.content')))]),_c('b-row',{staticClass:"list-news"},_vm._l((_vm.relatedNews),function(news){return _c('b-col',{key:("box-news-" + (news.article_id)),attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"news-box"},[_c('router-link',{staticClass:"thumbnail",attrs:{"to":{
                                    name: 'Detail',
                                    params: {
                                        type: news.blog_slug,
                                        id: news.article_slug,
                                        locale: _vm.Language
                                    },
                                }}},[_c('img',{attrs:{"src":news.article_thumbnail,"alt":""}})]),_c('div',{staticClass:"content-news"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime( news.created_at, news.article_lang ))+" ")]),_c('router-link',{attrs:{"to":{
                                            name: 'Detail',
                                            params: {
                                                type: news.blog_slug,
                                                id: news.article_slug,
                                                locale: _vm.Language
                                            },
                                        }}},[_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(news.article_blog_name)+" ")])])],1),_c('router-link',{attrs:{"to":{
                                        name: 'Detail',
                                        params: {
                                            type: news.blog_slug,
                                            id: news.article_slug,
                                            locale: _vm.Language
                                        },
                                    }}},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(news.article_title)+" ")])])],1)],1)])}),1)],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }